import React, {useContext, useEffect} from 'react'
import Header from './Header'
import LeftNav from './LeftNav'
import './layout.scss'
import {MainContextProvider} from "../../../context/MainContext";
import {Ticker} from "../../../components/other/Ticker/Ticker";
import {useIntercom} from "react-use-intercom";
import {ClientLayout} from "./ClientLayout/ClientLayout";


const Layout:React.FC<any> = ({ children, history }) => {
    const { boot } = useIntercom();
    const { isShowMainHeader, userData } = useContext(MainContextProvider)

    useEffect(() => {
        if (userData && window.location.host !== 'localhost:3000' && window.location.host !== 'stag.cabinet.legalnodes.co') boot({ userId: userData.user_id, name: userData.name })
    }, [userData])

    if(window.location.href.includes('/deliverable-view/')) {
        return (
            <div className={'layout'}>
                <div className={'layout__container'}>
                    {children}
                </div>
            </div>
        )
    }

    if (userData?.status.type_code === 100) {
        return (
            <ClientLayout children={children} />
        )
    }

    return <div className={'layout'}>
        <div style={{ maxWidth: '1600px', margin: '0 auto', width: '100%' }}>
            {isShowMainHeader && <Header />}
            <Ticker history={history} />
        </div>
        <div className={'layout__container'}>
            <div className={'layout__wrap'}>
                <LeftNav/>
                <div className={'layout__container'}>
                    {children}
                </div>
            </div>
        </div>
    </div>
}

export default Layout
